import { FC } from "react";
import "./roomGallery.scss";
import { Photo } from "../../types/photo";
import { useTranslation } from "react-i18next";

interface RoomGalleryProps {
  photos: Photo[]; 
}

const RoomGallery: FC<RoomGalleryProps> = ({ photos }) => {
  const { t } = useTranslation();
  const filteredPhotos = photos.filter((photo) => photo.semanticId === "bathroom");
  const isSlider = filteredPhotos.length > 2;

  return (
    <div className="room-gallery-wrapper">
      <h2 className="room-gallery-heading">{t("roomGallery.whereYouSleep")}</h2>

      {/* Галерея */}
      <div className={`room-gallery ${isSlider ? "room-gallery-slider" : ""}`}>
        {filteredPhotos.map((photo, index) => (
          <div key={index} className="room-gallery-item">
            <img src={photo.storageUrl} alt={photo.description} className="room-gallery-image" />
            <div className="room-gallery-info">
              <h3 className="room-gallery-title">{photo.semanticId}</h3>
              <p className="room-gallery-subtitle">{photo.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoomGallery;
