import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Booking } from "../../types/booking";
import LeftContent from "../../components/leftContent/leftContent";
import RightStickyContent from "../../components/rightStickyContent/rightStickyContent";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { checkAvailability, fetchBooking } from "../../api/api";
import shareIcon from "../../assets/images/share_icon.png";
import favoriteIcon from "../../assets/images/favorite_icon.png";
import "./bookingPage.scss";
import Reviews from "../../components/reviews/reviews";
import Map from "../../components/map/map";
import { Review } from "../../types/review";
import derekAvatar from "../../assets/images/derek.png";
import tomAvatar from "../../assets/images/tom.png";
import lizaAvatar from "../../assets/images/liza.png";
import HostDetails from "../../components/hostDetails/hostDetails";
import iconAnn from "../../assets/images/ann.png";
import { useTranslation } from "react-i18next";

const reviews: Review[] = [
  // TODO в юудущем эти данные будут приходить с бэкенда
  {
    name: "Derek",
    avatar: derekAvatar,
    rating: 4.5,
    date: "September 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
  {
    name: "Tom",
    avatar: tomAvatar,
    rating: 4.5,
    date: "September 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
  {
    name: "Liza",
    avatar: lizaAvatar,
    rating: 4.5,
    date: "September 2023",
    location: "11 months on Hofowo",
    text: "Great place, like advertised. Host was very responsive. Nice and quiet but close to everything within walking distance. Recommend it.",
  },
];

interface IBookingPageProps {
  onShowNotification: (
    message: string,
    type: "success" | "warning" | "error"
  ) => void;
}

const BookingPage: React.FC<IBookingPageProps> = ({ onShowNotification }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [guestCount, setGuestCount] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  });
  const [maxGuests, setMaxGuests] = useState(1);
  const [selectedDates, setSelectedDates] = useState<{
    checkIn: Date | undefined;
    checkOut: Date | undefined;
  }>({
    checkIn: undefined,
    checkOut: undefined,
  });

  const handleGuestLimitExceeded = () => {
    onShowNotification(t("bookingPage.guestLimitExceeded"), "warning");
  };

  const handleGuestChange = (type: string, increment: boolean) => {
    setGuestCount((prev) => {
      // Рассчитываем текущее количество основных гостей (взрослые + дети)
      const totalGuests = prev.adults + prev.children;

      // Если пытаемся увеличить количество основных гостей сверх maxGuests, показываем уведомление
      if (
        increment &&
        totalGuests >= maxGuests &&
        (type === "adults" || type === "children")
      ) {
        handleGuestLimitExceeded();
        return prev;
      }

      // Рассчитываем новое количество для конкретного типа гостей
      const newCount = increment
        ? prev[type as keyof typeof guestCount] + 1
        : prev[type as keyof typeof guestCount] - 1;

      // Возвращаем обновленное состояние, не допуская отрицательных значений
      return {
        ...prev,
        [type]: Math.max(0, newCount),
      };
    });
  };

  useEffect(() => {
    const getBooking = async () => {
      try {
        const response = await fetchBooking(id!);
        console.log(response.data);
        setBooking({
          ...response.data,
          location: {
            latitude: response.data.geoPoint.lat,
            longitude: response.data.geoPoint.lon,
          },
          id,
        });
        if (response.data.numGuests) {
          setMaxGuests(response.data.numGuests);
        }
      } catch (error) {
        console.error("Error fetching booking:", error);
      }
    };

    if (id) {
      getBooking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!booking) return <div>{t("bookingPage.loading")}</div>;

  const handleCheckAvailability = async () => {
    if (!selectedDates.checkIn || !selectedDates.checkOut) {
      onShowNotification(t("bookingPage.selectDates"), "warning");
      return;
    }
    if (guestCount.adults + guestCount.children === 0) {
      onShowNotification(t("bookingPage.addGuests"), "warning");
      return;
    }
    const nights = Math.ceil(
      (selectedDates.checkOut.getTime() - selectedDates.checkIn.getTime()) /
        (1000 * 60 * 60 * 24)
    );

    const formattedDate = selectedDates.checkIn.toISOString().split("T")[0]; // Формат YYYY-MM-DD

    try {
      const data = await checkAvailability(
        id!,
        formattedDate,
        nights,
        guestCount.adults + guestCount.children
      );
      console.log("Availability data:", data);
      onShowNotification(t("bookingPage.availabilityChecked"), "success");
    } catch (error) {
      onShowNotification(t("bookingPage.availabilityError"), "error");
    }
  };

  return (
    <div className="booking-page">
      <Header
        checkIn={selectedDates.checkIn}
        checkOut={selectedDates.checkOut}
        adults={guestCount.adults}
        children={guestCount.children}
        onCheckAvailability={handleCheckAvailability}
      />
      <div className="title-block">
        <h2 className="title-booking">{booking.name}</h2>
        <div className="title-block-right">
          <div className="block-share">
            <img
              src={shareIcon}
              alt={t("bookingPage.shareIconAlt")}
              className="share-icon"
            />
            <span className="share-text">{t("bookingPage.share")}</span>
          </div>
          <div className="favorite-block">
            <img
              src={favoriteIcon}
              alt={t("bookingPage.favoriteIconAlt")}
              className="favorite-icon"
            />
            <span className="favorite-text">
              {t("bookingPage.toFavorites")}
            </span>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="left">
          <LeftContent
            booking={booking}
            selectedDates={selectedDates}
            onDateChange={(checkInDate, checkOutDate) => {
              setSelectedDates({
                checkIn: checkInDate,
                checkOut: checkOutDate,
              });
            }}
          />
        </div>
        <div className="right">
          {/* TODO в будущем данные должны приходить с бэкенда */}
          <RightStickyContent
            guestCount={guestCount}
            setGuestCount={handleGuestChange}
            pricePerNight={46}
            price={3}
            maxGuests={maxGuests}
            selectedDates={selectedDates}
            onDateChange={(checkInDate, checkOutDate) => {
              setSelectedDates({
                checkIn: checkInDate,
                checkOut: checkOutDate,
              });
            }}
            onCheckAvailability={handleCheckAvailability}
          />
        </div>
      </div>
      <Reviews reviews={reviews} />
      <Map location={booking.location} />
      {/* TODO в будущем данные должны приходить с бэкенда */}
      <HostDetails
        name="Ann"
        joinedDate="August 2019"
        verified={true}
        reviewsCount={63}
        bio="Good day! What is important to know about us? So this is what we are people. We try to make the life of our clients happier, and the rest in Phuket more comfortable. We care about the interests of our guests, help them in everything, advise."
        languages={["English", "Русский"]}
        responseRate="91%"
        responseTime="within an hour"
        avatarUrl={iconAnn}
      />
      <Footer booking={booking} />
    </div>
  );
};

export default BookingPage;
