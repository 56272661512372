import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import "./header.scss";
import leftHeader from "../../assets/images/header_left.png";
import locationMarker from "../../assets/images/location-marker.png";
import calendarIcon from "../../assets/images/calendar.png";
import usersIcon from "../../assets/images/users.png";
import adjustmentIcon from "../../assets/images/adjustments.png";
import searchIcon from "../../assets/images/search_icon.png";
import userAvatar from "../../assets/images/avatar.png";

interface IHeaderProps {
  checkIn?: Date | undefined;
  checkOut?: Date | undefined;
  adults?: number;
  children?: number;
  onCheckAvailability?: () => void;
}

const Header: React.FC<IHeaderProps> = ({
  checkIn,
  checkOut,
  adults,
  children,
  onCheckAvailability,
}) => {
  const { t } = useTranslation();

  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    setIsSticky(window.scrollY > 130);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -102;
      const yPosition =
        section.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({
        top: yPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <header className={`header ${isSticky ? "sticky" : ""}`}>
      <div className="header-content">
        {/* Левая часть */}
        <div className="header-left">
          {!isSticky ? (
            <div className="navigation">
              <div className="location-tag">
                <img className="leftHeaderIcon" src={leftHeader} alt="icon" />
                <div className="location-place">
                  <img
                    className="locationMarker"
                    src={locationMarker}
                    alt="location marker"
                  />
                  <span className="location-text">Istanbul</span>
                </div>
              </div>
              <div className="separator"></div>
              <div className="date-block">
                <img
                  src={calendarIcon}
                  alt="icon calendar"
                  className="calendar-icon"
                />
                <span className="calendar-text">
                  {checkIn && checkOut
                    ? `${checkIn.toLocaleDateString()} - ${checkOut.toLocaleDateString()}`
                    : t("header.dates")}
                </span>
              </div>
              <div className="separator"></div>
              <div className="guests-block">
                <img
                  src={usersIcon}
                  alt="icon guests"
                  className="guests-icon"
                />
                <span className="guests-qantity">
                  {(adults || 0) + (children || 0)}
                </span>
              </div>
              <div className="separator"></div>
              <div className="adjustment-block">
                <img
                  src={adjustmentIcon}
                  alt="adjustments icon"
                  className="adjustment-icon"
                />
              </div>
              <img src={searchIcon} alt="search icon" className="search-icon" />
            </div>
          ) : (
            <div className="sticky-navigation">
              <img className="leftHeaderIcon" src={leftHeader} alt="icon" />
              <nav>
                <button onClick={() => scrollToSection("photos")}>
                  {t("header.Photos")}
                </button>
                <button onClick={() => scrollToSection("amenities")}>
                  {t("header.Amenities")}
                </button>
                <button onClick={() => scrollToSection("reviews")}>
                  {t("header.Reviews")}
                </button>
                <button onClick={() => scrollToSection("location")}>
                  {t("header.Location")}
                </button>
              </nav>
            </div>
          )}
        </div>

        {/* Правая часть */}
        <div className="header-right-sticky">
          {isSticky ? (
            <div className="price-block">
              <p className="price">$67&nbsp;{t("header.night")}</p>
              <p className="header-reviews">12 {t("header.reviews")}</p>
              <button className="check-dates-btn" onClick={onCheckAvailability}>
                {t("header.Check-available-dates")}
              </button>
            </div>
          ) : (
            <>
              <LanguageSwitcher />
              <div className="user-avatar">
                <img
                  src={userAvatar}
                  alt="User Avatar"
                  className="avatar-image"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
