import React, { useEffect, useRef, useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import "./rightStickyContent.scss";
import arrowDownIcon from "../../assets/images/arrow_down.png";
import minusIcon from "../../assets/images/minus.png";
import plusIcon from "../../assets/images/plus.png";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";

interface RightStickyContentProps {
  price: number;
  pricePerNight: number;
  maxGuests: number;
  selectedDates: { checkIn: Date | undefined; checkOut: Date | undefined };
  onDateChange: (
    checkInDate: Date | undefined,
    checkOutDate: Date | undefined
  ) => void;
  setGuestCount: (type: string, increment: boolean) => void;
  guestCount: {
    adults: number;
    children: number;
    infants: number;
    pets: number;
  };
  onCheckAvailability: () => void;
}

const RightStickyContent: React.FC<RightStickyContentProps> = ({
  price,
  pricePerNight,
  maxGuests,
  selectedDates,
  onDateChange,
  setGuestCount,
  guestCount,
  onCheckAvailability,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isGuestDropdownOpen, setIsGuestDropdownOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === "ru" ? ru : enUS;

  const totalGuests = guestCount.adults + guestCount.children;
  const toggleGuestDropdown = () => {
    setIsGuestDropdownOpen((prev) => !prev);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  const handleDateChange = (range: DateRange | undefined) => {
    onDateChange(range?.from || undefined, range?.to || undefined);
  };

  const handleResetDates = () => {
    onDateChange(undefined, undefined);
  };

  const handleGuestChange = (type: string, increment: boolean) => {
    setGuestCount(type, increment);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    if (isDatePickerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDatePickerOpen]);

  return (
    <div className="right-sticky-content">
      <div className="sticky-block">
        <div className="price-info">
          <h2>${price}</h2>
          <span>
            {pricePerNight}$/{t("rightStickyContent.night")}
          </span>
        </div>
        <div className="date-inputs" onClick={toggleDatePicker}>
          <div className="date-input">
            <span className="date-input-label">
              {t("rightStickyContent.Check-in")}
            </span>
            <span className="date-input-placeholder">
              {selectedDates.checkIn
                ? selectedDates.checkIn.toLocaleDateString()
                : t("rightStickyContent.Please-enter-a-date")}
            </span>
          </div>
          <div className="date-input">
            <span className="date-input-label">
              {t("rightStickyContent.Check-out")}
            </span>
            <span className="date-input-placeholder">
              {selectedDates.checkOut
                ? selectedDates.checkOut.toLocaleDateString()
                : t("rightStickyContent.Please-enter-a-date")}
            </span>
          </div>
        </div>
        {/* Date Picker */}
        {isDatePickerOpen && (
          <div className="date-picker" ref={datePickerRef}>
            <DayPicker
              mode="range"
              selected={{
                from: selectedDates.checkIn,
                to: selectedDates.checkOut,
              }}
              onSelect={handleDateChange}
              disabled={{ before: new Date() }}
              locale={currentLocale}
              formatters={{
                formatCaption: (date) =>
                  format(date, "LLLL yyyy", { locale: currentLocale }),
              }}
              numberOfMonths={1}
            />
            <button onClick={handleResetDates} className="reset-dates-button">
              {t("rightStickyContent.Reset-Dates")}
            </button>
          </div>
        )}
        <div className="guest-dropdown">
          <button onClick={toggleGuestDropdown}>
            <div className="guest-dropdown-left">
              <span className="guest-dropdown-placeholder">
                {t("rightStickyContent.Guests")}
              </span>
              <span className="guest-label">
                {t("rightStickyContent.guests", { count: totalGuests })}
              </span>
            </div>
            <img
              src={arrowDownIcon}
              alt="Arrow Down"
              className="dropdown-icon"
            />
          </button>
          {isGuestDropdownOpen && (
            <div className="guest-dropdown-menu">
              {["adults", "children", "infants", "pets"].map((type, index) => (
                <div key={index} className="guest-item">
                  <div className="guest-labels">
                    <span className="guest-title">
                      {t(`rightStickyContent.${type}.title`)}
                    </span>
                    <span className="guest-subtitle">
                      {t(`rightStickyContent.${type}.subtitle`)}
                    </span>
                  </div>
                  <div className="guest-controls">
                    <button
                      onClick={() => handleGuestChange(type, false)}
                      disabled={
                        guestCount[type as keyof typeof guestCount] === 0
                      }
                    >
                      <img
                        src={minusIcon}
                        alt={t("rightStickyContent.minusIconAlt")}
                        className="minus-icon"
                      />
                    </button>
                    <span>{guestCount[type as keyof typeof guestCount]}</span>
                    <button onClick={() => handleGuestChange(type, true)}>
                      <img
                        src={plusIcon}
                        alt={t("rightStickyContent.plusIconAlt")}
                        className="plus-icon"
                      />
                    </button>
                  </div>
                </div>
              ))}
              <p>
                {t("rightStickyContent.maxGuestsMessage", {
                  maxGuests,
                })}
              </p>
              <button onClick={toggleGuestDropdown} className="close-button">
                {t("rightStickyContent.close")}
              </button>
            </div>
          )}
        </div>
        <button className="availability-button" onClick={onCheckAvailability}>
          {t("rightStickyContent.checkAvailableDates")}
        </button>
      </div>
    </div>
  );
};

export default RightStickyContent;
