import React, { useState } from "react";
import "./listingDescription.scss";
import { useTranslation } from "react-i18next";

interface Feature {
  id: string;
  icon: string;
  name: string;
  description: string;
}

interface ListingDescriptionProps {
  features: Feature[];
  translatedInfo: string;
  originalInfo: string;
}

const ListingDescription: React.FC<ListingDescriptionProps> = ({
  features,
  translatedInfo,
  originalInfo,
}) => {
  const { t } = useTranslation();
  const [showOriginal, setShowOriginal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleOriginal = () => setShowOriginal((prev) => !prev);
  const toggleDescription = () => setShowFullDescription((prev) => !prev);

  return (
    <div className="listing-description">
      <div className="listing-features">
        {features.map((feature) => (
          <div key={feature.id} className="feature-item">
            <img
              src={feature.icon}
              alt={feature.name}
              className="feature-icon"
            />
            <div className="feature-text">
              <h4 className="feature-title">{feature.name}</h4>
              <p className="feature-description">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="translation-info">
        <p>
          {showOriginal ? originalInfo : translatedInfo}{" "}
          <button className="translation-toggle" onClick={toggleOriginal}>
            {showOriginal
              ? t("listingDescription.showTranslation")
              : t("listingDescription.showOriginal")}
          </button>
        </p>
      </div>
      <div className="description-text">
        <p>
          {showFullDescription
            ? originalInfo
            : `${originalInfo.slice(0, 200)}...`}
        </p>
        <button className="description-toggle" onClick={toggleDescription}>
          {showFullDescription
            ? t("listingDescription.showLess")
            : t("listingDescription.showMore")}
        </button>
      </div>
    </div>
  );
};

export default ListingDescription;
