import React, { useState } from "react";
import "./placeFeatures.scss";
import { Feature } from "../../types/feature";
import kitchenIcon from "../../assets/images/icon-features/kitchen.png";
import parkingIcon from "../../assets/images/icon-features/parking.png";
import tvIcon from "../../assets/images/icon-features/tv.png";
import washerIcon from "../../assets/images/icon-features/washer.png";
import balconyIcon from "../../assets/images/icon-features/balcony.png";
import wifiIcon from "../../assets/images/icon-features/wifi.png";
import poolIcon from "../../assets/images/icon-features/pool.png";
import elevatorIcon from "../../assets/images/icon-features/lift.png";
import airConditionerIcon from "../../assets/images/icon-features/snowflake.png";
import carbonMonoxideIcon from "../../assets/images/icon-features/carbon.png";
import { useTranslation } from "react-i18next";

interface PlaceFeaturesProps {
  features: Feature[];
}

const getIconBySemanticId = (semanticId: string): string => {
  switch (semanticId) {
    case "kitchen":
      return kitchenIcon;
    case "parking":
      return parkingIcon;
    case "tv":
      return tvIcon;
    case "washer":
      return washerIcon;
    case "balcony":
      return balconyIcon;
    case "wifi":
      return wifiIcon;
    case "pool":
      return poolIcon;
    case "elevator":
      return elevatorIcon;
    case "air_conditioner":
      return airConditionerIcon;
    case "carbon_monoxide":
      return carbonMonoxideIcon;
    default:
      return balconyIcon; // Иконка по умолчанию
  }
};

const PlaceFeatures: React.FC<PlaceFeaturesProps> = ({ features }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const maxVisibleFeatures = 10;

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  // Определяем, какие удобства показывать
  const visibleFeatures = showAll
    ? features
    : features.slice(0, maxVisibleFeatures);

  return (
    <div className="place-features" id="amenities">
      <h2 className="place-features-heading">{t("placeFeatures.heading")}</h2>

      {/* Список удобств */}
      <ul className="place-features-list">
        {visibleFeatures.map((feature) => (
          <li key={feature.id} className="place-feature-item">
            <img
              src={getIconBySemanticId(feature.semanticId)}
              alt={feature.name}
              className="place-feature-icon"
            />
            <span className="place-feature-name">{feature.name}</span>
          </li>
        ))}
      </ul>

      {/* Кнопка "Показать больше" */}
      {features.length > maxVisibleFeatures && (
        <button className="place-features-toggle" onClick={toggleShowAll}>
          {showAll
            ? t("placeFeatures.showLess")
            : t("placeFeatures.showAll", { count: features.length })}
        </button>
      )}
    </div>
  );
};

export default PlaceFeatures;
