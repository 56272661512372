import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './languageSwitcher.scss';
import englishFlag from '../../assets/images/united kingdom.png';

const languages = [
  { code: 'en', name: 'Eng', icon: englishFlag }, 
  { code: 'ru', name: 'Рус', icon: englishFlag }, // TODO надо будет поискать иконку флага
];

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0]; 
  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    setIsOpen(false);
  };

  return (
    <div className="language-switcher">
      <button onClick={toggleMenu} className="language-button">
        <img
          src={currentLanguage.icon}
          alt={`${currentLanguage.name} flag`}
          className="image-icon"
        />
      </button>
      <p className='text-language'>{currentLanguage.name}</p>
      {isOpen && (
        <div className="language-menu">
          {languages.map((language) => (
            <button
              key={language.code}
              onClick={() => changeLanguage(language.code)}
              className="language-option"
            >
              <img
                src={language.icon}
                alt={`${language.name} flag`}
                className="language-icon"
              />
              {language.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
