import React, { useEffect, useState } from "react";
import { Photo } from "../../types/photo";
import styles from "./photoGallery.module.scss";

interface PhotoGalleryProps {
  photos: Photo[];
  onClose: () => void;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ photos, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
    setIsImageLoaded(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
    setIsImageLoaded(false);
  };

  useEffect(() => {
    // Отключаем скролл при открытии галереи
    document.body.style.overflow = "hidden";

    return () => {
      // Включаем скролл при закрытии галереи
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className={styles.photoGalleryModal}>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={styles.photoGalleryContent}>
        {!isImageLoaded && <div className={styles.loader}>Loading...</div>}
        <button
          className={`${styles.closeBtn} ${
            isImageLoaded ? styles.visible : ""
          }`}
          onClick={onClose}
        >
          ✕
        </button>
        <div className={styles.photoContainer}>
          <img
            src={photos[currentIndex].storageUrl}
            alt={
              photos[currentIndex].description || `Photo ${currentIndex + 1}`
            }
            className={`${styles.photo} ${isImageLoaded ? styles.visible : ""}`}
            onLoad={() => setIsImageLoaded(true)}
          />
        </div>
        {isImageLoaded && (
          <>
            <button
              className={`${styles.arrow} ${styles.leftArrow} ${styles.visible}`}
              onClick={handlePrev}
            >
              ◀
            </button>
            <button
              className={`${styles.arrow} ${styles.rightArrow} ${styles.visible}`}
              onClick={handleNext}
            >
              ▶
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PhotoGallery;
