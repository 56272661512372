import React from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <Header />
      <div className="other-page-content">
        <h1>Not Found</h1>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
