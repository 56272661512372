import React from "react";
import { DayPicker, DateRange } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./calendar.scss";
import { format } from "date-fns";
import { enUS, ru } from "date-fns/locale";
import { useTranslation } from "react-i18next";

interface ICalendarProps {
  selectedDates: { checkIn: Date | undefined; checkOut: Date | undefined };
  onDateChange: (
    checkInDate: Date | undefined,
    checkOutDate: Date | undefined
  ) => void;
}

const Calendar: React.FC<ICalendarProps> = ({
  selectedDates,
  onDateChange,
}) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === "ru" ? ru : enUS;
  const handleDateChange = (range: DateRange | undefined) => {
    onDateChange(range?.from || undefined, range?.to || undefined);
  };

  const handleClearDates = () => {
    onDateChange(undefined, undefined);
  };

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <h3>{t("calendar.selectCheckIn")}</h3>
        <button className="clear-dates" onClick={handleClearDates}>
          {t("calendar.clearDates")}
        </button>
      </div>
      <p>{t("calendar.addDatesForPricing")}</p>
      <div className="calendar-inline">
        {/* Первый календарь */}
        <DayPicker
          mode="range"
          selected={{ from: selectedDates.checkIn, to: selectedDates.checkOut }}
          onSelect={handleDateChange}
          numberOfMonths={1}
          disabled={{ before: new Date() }}
          locale={currentLocale}
          formatters={{
            formatCaption: (date) =>
              format(date, "LLLL yyyy", { locale: currentLocale }),
          }}
          styles={{
            day: { width: "62.35px", height: "48px", margin: "auto" },
            caption: {
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
            },
            table: { maxWidth: "none" },
          }}
        />
        {/* Второй календарь */}
        <DayPicker
          mode="range"
          selected={{ from: selectedDates.checkIn, to: selectedDates.checkOut }}
          onSelect={handleDateChange}
          numberOfMonths={1}
          disabled={{ before: new Date() }}
          locale={currentLocale}
          formatters={{
            formatCaption: (date) =>
              format(date, "LLLL yyyy", { locale: currentLocale }),
          }}
          styles={{
            day: { width: "62.35px", height: "48px", margin: "auto" },
            caption: {
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
            },
            table: { maxWidth: "none" },
          }}
        />
      </div>
    </div>
  );
};

export default Calendar;
