import React from "react";
import { Booking } from "../../types/booking";
import "./bookingDetails.scss";
import chatIcon from "../../assets/images/chat_icon.png";
import { useTranslation } from "react-i18next";

interface BookingDetailsProps {
  booking: Booking;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ booking }) => {
  const { t } = useTranslation();
  return (
    <div className="booking-details">
      <h3 className="title-booking-details">
        {booking.autogeneratedDescription}
      </h3>
      <p className="parameters-block">
        {`${t("bookingDetails.guests", { count: booking.numGuests })} | 
    ${t("bookingDetails.bedrooms", { count: booking.numBedrooms })} | 
    ${t("bookingDetails.beds", { count: booking.numBeds })} | 
    ${t("bookingDetails.baths", { count: booking.numBathrooms })}`}
      </p>
      <div className="chat-block">
        <div className="chat-block-element">
          <img
            src={chatIcon}
            alt={t("bookingDetails.chatIconAlt")}
            className="chat-icon"
          />
          {/* TODO захордкоженный текст */}
          <span className="chat-text">
            {" "}
            {t("bookingDetails.reviews", { count: 3 })}
          </span>
        </div>
        <p className="chat-block-text">{t("bookingDetails.reportListing")}</p>
      </div>
    </div>
  );
};

export default BookingDetails;
