import React from "react";
import "./footer.scss";
import { Booking } from "../../types/booking";
import { useTranslation } from "react-i18next";

interface IFooter {
  booking?: Booking;
}
const Footer: React.FC<IFooter> = ({ booking }) => {
  const { t } = useTranslation();
  const handleShowMore = (section: string) => {
    console.log(`Show more clicked for ${section}`);
  };

  return (
    <footer className="footer">
      <div className="footer-top">
        <h3 className="footer-heading">{t("footer.thingsToKnow")}</h3>
        <div className="footer-columns">
          <div className="footer-column">
            <h4 className="footer-column-heading">{t("footer.houseRules")}</h4>
            <ul>
              <li>{t("footer.checkIn", { time: "2:00 PM" })}</li>
              <li>{t("footer.checkout", { time: "12:00 PM" })}</li>
              <li>{t("footer.maxGuests", { count: booking?.numGuests || 0 })}</li>
              <li>
                <button
                  className="footer-button"
                  onClick={() => handleShowMore("House rules")}
                >
                  {t("footer.showMore")}
                </button>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4 className="footer-column-heading">
              {t("footer.safetyAndProperty")}
            </h4>
            <ul>
              <li>{t("footer.coAlarm")}</li>
              <li>{t("footer.smokeAlarm")}</li>
              <li>
                <button
                  className="footer-button"
                  onClick={() => handleShowMore("Safety & property")}
                >
                  {t("footer.showMore")}
                </button>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4 className="footer-column-heading">
              {t("footer.cancellationPolicy")}
            </h4>
            <ul>
              <li>{t("footer.cancellationDetails")}</li>
              <li>
                <button
                  className="footer-button"
                  onClick={() => handleShowMore("Cancellation policy")}
                >
                  {t("footer.showMore")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <span>© 2023 {t("footer.companyName")}</span>
        <div className="footer-links">
          <a href="/help">{t("footer.help")}</a>
          <a href="/sitemap">{t("footer.sitemap")}</a>
          <a href="/terms">{t("footer.terms")}</a>
          <a href="/privacy">{t("footer.privacyPolicy")}</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
