import React, { useState } from "react";
import { Review } from "../../types/review";
import "./reviews.scss";
import starIcon from '../../assets/images/star.png';
import { useTranslation } from "react-i18next";

interface ReviewsProps {
  reviews: Review[];
}

const Reviews: React.FC<ReviewsProps> = ({ reviews }) => {
  const { t } = useTranslation();
  const [visibleCount, setVisibleCount] = useState<number>(3); // Количество видимых отзывов

  const handleShowMore = () => {
    setVisibleCount((prev) => prev + 3); // Показываем больше отзывов
  };

  return (
    <div className="reviews" id="reviews">
      <div className="reviews-top">
      <h3 className="reviews-heading">{t("reviews.heading", { count: reviews.length })}</h3>
      {visibleCount < reviews.length && (
        <button className="reviews-show-more" onClick={handleShowMore}>
          {t("reviews.showMore")}
        </button>
      )}
      </div>
      <p className="reviews-subheading">
      {t("reviews.subheading")}
      </p>
      <div className="reviews-list">
        {reviews.slice(0, visibleCount).map((review, index) => (
          <div key={index} className="review-item">
            <div className="review-avatar">
              <img src={review.avatar} alt={`${review.name}'s avatar`} />
            </div>
            <div className="review-content">
              <h4 className="review-name">{review.name}</h4>
              <div className="review-details">
                <div className="review-rating">
                  <img src={starIcon} alt="star icon" className="review-star-icon"/>
                  <span className="review-rating-text">{review.rating}</span>
                  </div>
                <span className="review-date">{review.date}</span>
                <span className="review-location">{review.location}</span>
              </div>
              <p className="review-text">{review.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
