import React from "react";
import styles from "./hostInfo.module.scss";
import { useTranslation } from "react-i18next";

interface HostInfoProps {
  avatarUrl: string;
  name: string;
  hostingYears: number;
}

const HostInfo: React.FC<HostInfoProps> = ({
  avatarUrl,
  name,
  hostingYears,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles["host-info"]}>
      <img
        src={avatarUrl}
        alt={t("hostInfo.avatarAlt", { name })}
        className={styles["host-avatar"]}
      />
      <div className={styles["host-details"]}>
        <h3 className={styles["host-name"]}>
          {t("hostInfo.hostedBy", { name })}
        </h3>
        <p className={styles["host-years"]}>
          {t("hostInfo.hostingYears", { count: hostingYears })}
        </p>
      </div>
    </div>
  );
};

export default HostInfo;
