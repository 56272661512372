import React, { useEffect } from "react";
import styles from "./notification.module.scss";

interface NotificationProps {
  type: "success" | "error" | "warning";
  message: string;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ type, message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`${styles.notification} ${styles[`notification-${type}`]}`}>
      <span>{message}</span>
      <button onClick={onClose} className={styles["close-button"]}>
        ×
      </button>
    </div>
  );
};

export default Notification;
