import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";
import BookingPage from "./pages/bookingPage/bookingPage";
import Notification from "./components/notification/notification";

const App: React.FC = () => {
  const [notification, setNotification] = useState<{
    message: string;
    type: "success" | "warning" | "error";
  } | null>(null);

  const showNotification = (
    message: string,
    type: "success" | "warning" | "error"
  ) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  return (
    <div className="app-container">
      <Router>
        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification(null)}
          />
        )}
        <Routes>
          <Route
            path="/booking/:id"
            element={
              <BookingPage
                onShowNotification={(message, type) =>
                  showNotification(message, type)
                }
              />
            }
          />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
