import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Импорт локальных файлов с переводами
import enTranslation from './locales/en/translation.json';
import ruTranslation from './locales/ru/translation.json';

i18n
  .use(LanguageDetector) // Для автоматического определения языка
  .use(initReactI18next) // Для интеграции с React
  .init({
    fallbackLng: 'en', // Язык по умолчанию
    supportedLngs: ['en', 'ru'], // Поддерживаемые языки
    resources: {
      en: {
        translation: enTranslation, // Переводы для английского
      },
      ru: {
        translation: ruTranslation, // Переводы для русского
      },
    },
    interpolation: {
      escapeValue: false, // React уже экранирует значения
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'], // Кэширование языка
    },
    pluralSeparator: '_',
  });

export default i18n;
