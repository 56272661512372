import axios from "axios";

const API = axios.create({
  baseURL: "https://app-staging.selof.ru/api/v1",
});

// Основная информация об апартаментах
export const fetchBooking = (id: string) => API.get(`object/rentals/${id}`);

// Доступные даты для календаря
export const getAvailableNights = (id: string) =>
  API.get(`/booking/rentals/${id}/nights`);

// Хост (владелец апартаментов)
export const fetchHost = (participantId: string) =>
  API.get(`/profile/participants/${participantId}`);

// Занятость апартаментов
export const fetchOccupancies = (id: string) =>
  API.get(`/booking/rentals/${id}/occupancies`);

// Правила и ограничения
export const fetchRestrictions = (id: string) =>
  API.get(`/object/restrictions?filter[rental]=${id}`);

export const getAddressFromCoordinates = async (
  latitude: number,
  longitude: number,
  language: string
): Promise<string | null> => {
  try {
    const response = await axios.get(
      "https://nominatim.openstreetmap.org/reverse",
      {
        params: {
          lat: latitude,
          lon: longitude,
          format: "json",
          "accept-language": language,
        },
      }
    );
    return response.data.display_name; // Возвращает полный адрес
  } catch (error) {
    console.error("Ошибка при геокодировании:", error);
    return null; // В случае ошибки
  }
};

export const checkAvailability = async (
  id: string,
  from: string,
  nights: number,
  numGuests: number
) =>
  API.get("/search/rentals", {
    params: {
      id,
      "book[from]": from,
      "book[nights]": nights,
      "numGuests[gte]": numGuests,
    },
  });
